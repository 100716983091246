import React, { useState } from "react";
import { LucideIcon, PlusIcon, MinusIcon } from "lucide-react"; // Usa lucide-react para os ícones.
import { cn } from "#app/utils/misc";

export type NumberPickerProps = {
  value?: number;  // Controlado
  defaultValue?: number;  // Não controlado
  min?: number;  // Valor mínimo
  max?: number;  // Valor máximo
  step?: number;  // Incremento/decremento
  onChange?: (newValue: number, config:{
    source?: 'input' | 'button', 
    setInternalValue: React.Dispatch<React.SetStateAction<number>>
    }) => void;  // Evento para mudança de valor
  onBlur?: (value: number, config:{
    setInternalValue: React.Dispatch<React.SetStateAction<number>>
  }) => void;  // Validação ao sair do input
  incrementButton?: LucideIcon;  // Customização de botão +
  decrementButton?: LucideIcon;  // Customização de botão -
  buttonsClassName?: string;
  className?: string;
}


export const NumberPicker = React.forwardRef<HTMLInputElement, NumberPickerProps>(({
  value,
  defaultValue = 0,
  min = 0,
  max = 10000000,
  step = 1,
  onChange,
  onBlur,
  incrementButton: IncrementButton = PlusIcon,
  decrementButton: DecrementButton = MinusIcon,
  className,
  buttonsClassName
}, ref) => {
  const [internalValue, setInternalValue] = useState<number>(defaultValue);
  
  const isControlled = value !== undefined;

  const getValue = () => (isControlled ? value! : internalValue);

  const setValue = (newValue: number, source?: 'button' | 'input') => {
    let nextValue = newValue;
    if(newValue < min) {
      nextValue = min;
      return;
    }

    if(newValue > max) {
      nextValue = max;
      return;
    }

    if (isControlled && onChange) {
      onChange(nextValue, {source, setInternalValue});
    } else {
      setInternalValue(nextValue);
      onChange && onChange(nextValue, {source, setInternalValue});
    }
  };

  const handleIncrement = () => {
    setValue(getValue() + step);
  };

  const handleDecrement = () => {
    setValue(getValue() - step);
  };

  const handleBlur = () => {
    const currentValue = getValue();
    if (onBlur) {
      onBlur(currentValue, {setInternalValue});
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    const isInvalid = isNaN(newValue);

    if (!isNaN(newValue)) {
      setValue(newValue, 'input');
    }
    if(isInvalid && typeof min !== 'undefined') {
        setValue(min, 'input')
    }
    
  };
  
  const buttonClasses = cn("bg-brand text-white p-2 rounded-full", buttonsClassName)

  return (
    <div className="flex items-center gap-2 border rounded-full p-1">
      {/* Botão de decremento */}
      <button
        className={cn("z-10 left-1z", buttonClasses)}
        onClick={handleDecrement}
        disabled={getValue() <= min}
        type="button"
      >
        <DecrementButton size={16} />
      </button>
        {/* Input de número */}
        <input
        type="number"
        className={cn("w-full outline-none border-gray-300 text-center rounded hide-number-input-arrow", className)}
        value={getValue()}
        onChange={handleChange}
        onBlur={handleBlur}
        min={min}
        max={max}
        step={step}
        ref={ref}
      />

      {/* Botão de incremento */}
      <button
        className={cn("z-10 right-1z", buttonClasses)}
        onClick={handleIncrement}
        disabled={getValue() >= max}
        type="button"
      >
        <IncrementButton size={16} />
      </button>
      
    </div>
  );
});